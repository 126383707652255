// src/App.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import './ContactForm.css';

// Import images - add these imports at the top of your file
// You'll need to have these images in your src/images directory
import sealcoatingImg from './images/sealcoat_2.jpg';
import parkingLotImg from './images/sealcoat_3.jpg';
import drivewayImg from './images/sealcoat_4.jpg';
import repairImg from './images/sealcoat_5.jpg';
import asphaltParkinglot from './images/asphaltParkinglot.jpg';
import pavingServiceImg from './images/asphalt-paving-service.jpg';
import crewOnJob from './images/crew_on_Job.jpg';
import crackFillingImg from './images/asphalt-crack-filling-services.jpg';
import stripingImg from './images/asphalt-parking-lot-striping-services.jpg';
import crewImg from './images/parking-lot-paving.jpg';

// Mobile Navigation Toggle Component
function MobileNavToggle({ isOpen, toggle }) {
  return (
    <button 
      className={`mobile-nav-toggle ${isOpen ? 'active' : ''}`}
      onClick={toggle}
      aria-label="Toggle navigation menu"
    >
      <div className="hamburger">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  );
}



function App() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  
  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
    // Toggle body scroll when mobile menu is open
    document.body.style.overflow = !mobileNavOpen ? 'hidden' : '';
  };
  
  const closeNav = () => {
    setMobileNavOpen(false);
    document.body.style.overflow = '';
  };

  // Scroll to section helper function
  const scrollToSection = (sectionId) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      const headerHeight = document.querySelector('.header').offsetHeight;
      const scrollToPosition = 
        window.pageYOffset + 
        targetElement.getBoundingClientRect().top - 
        headerHeight - 20; // Added extra padding
      
      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      });
    }
  };

  // Handle smooth scrolling for navigation links - this captures all link clicks
  useEffect(() => {
    const handleNavClick = (e) => {
      // Only process links pointing to page sections (starting with #)
      if (e.target.tagName === 'A' && e.target.getAttribute('href')?.startsWith('#')) {
        const href = e.target.getAttribute('href');
        
        // If it's just "#" (like a toggle button), don't do anything special
        if (href === '#') {
          return;
        }
        
        e.preventDefault();
        
        // Close mobile menu if open
        setMobileNavOpen(false);
        document.body.style.overflow = '';
        
        const targetId = href.substring(1);
        scrollToSection(targetId);
      }
    };
    
    // Add the click event to the document to catch all navigation clicks
    document.addEventListener('click', handleNavClick);
    
    // Clean up
    return () => {
      document.removeEventListener('click', handleNavClick);
    };
  }, []);

  return (
    <div className={mobileNavOpen ? 'mobile-nav-open' : ''}>
      {/* Enhanced SEO with Meta Tags */}
      <Helmet>
        <title>Phillips Paving Indianapolis | Asphalt Paving, Sealcoating & Repair Since 1983</title>
        <meta
          name="description"
          content="Phillips Paving Inc is Indianapolis' trusted asphalt contractor with 40+ years experience in driveway paving, asphalt repair, and sealcoating near me services. Free estimates!"
        />
        <meta name="keywords" content="asphalt paving indianapolis, driveway paving, asphalt indianapolis, phillips paving indianapolis, asphalt sealcoating near me, driveway sealing indianapolis, asphalt companies indianapolis, asphalt contractors near me, asphalt paving, asphalt repair near me" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://phillipspaving.com" />
      </Helmet>

      {/* TOP BAR */}
      <div className="top-bar">
        <div className="container top-bar-content">
          <span className="years-in-business">Trusted Asphalt Contractors in Indianapolis Since 1983 - Over 40 Years of Excellence</span>
          <span className="phone-number">Call us: <a href="tel:3177831228">(317) 783-1228</a></span>
        </div>
      </div>

      {/* HEADER / NAVBAR */}
      <header className="header">
        <div className="container header-content">
          <div className="logo">Phillips Paving Indianapolis</div>
          
          {/* Mobile Navigation Toggle Button */}
          <MobileNavToggle isOpen={mobileNavOpen} toggle={toggleMobileNav} />
          
          {/* Nav Overlay for Mobile */}
          <div 
            className="nav-overlay"
            onClick={closeNav}
          ></div>
          
          <nav className="nav-links">
            <a href="#services">Asphalt Services</a>
            <a href="#Quote">Free Quote</a>
            <a href="#about">About</a>
            <a href="#reviews">Reviews</a>
            <a href="#careers">We're Hiring</a>
            <a href="#contact">Contact</a>
          </nav>
        </div>
      </header>

      {/* HERO SECTION */}
      <section className="hero-section" style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${crewOnJob || 'https://via.placeholder.com/1200x600/333333/FFFFFF?text=Sealcoating'})`
      }}>
        <div className="overlay"></div>
        <div className="hero-overlay">
          <h1>Premier Asphalt Paving & Sealcoating in Indianapolis</h1>
          <p>Expert Driveway Paving • Asphalt Repair Near Me • Sealcoating Services • Family Owned Since 1983 • Free Estimates</p>
          <div className="hero-buttons">
            <button className="primary-button pulse-button" onClick={() => scrollToSection('Quote')}>
              Get a Free Quote
            </button>
            <button className="secondary-button" onClick={() => scrollToSection('services')}>
              Explore Our Asphalt Services
            </button>
          </div>
        </div>
      </section>

      {/* Add the Quote Section here */}
      <section id="Quote" className="section alt-bg">
        <div className="container">
        <QuoteSection />
        </div>
      </section>
      
      {/* FEATURED SEALCOATING SECTION */}
      <section id="sealcoating" className="section sealcoating-highlight">
        <div className="container">
          <div className="section-header">
            <h2>Professional <span>Asphalt Sealcoating Near Me</span> Services</h2>
            <div className="section-divider"></div>
            <p className="section-subheader">
              Protect your Indianapolis asphalt investment and extend its life with our premium driveway sealing and sealcoating services.
            </p>
          </div>
          
          <div className="sealcoating-benefits">
            <div className="benefit-item">
              <div className="benefit-icon">
                <i className="icon-shield"></i>
              </div>
              <h3>Protection</h3>
              <p>Shields your asphalt from water damage, UV rays, and chemical spills</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-icon">
                <i className="icon-money"></i>
              </div>
              <h3>Cost Savings</h3>
              <p>Helps to prevent expensive repairs and extends pavement life</p>
            </div>
            <div className="benefit-item">
              <div className="benefit-icon">
                <i className="icon-home"></i>
              </div>
              <h3>Curb Appeal</h3>
              <p>Restores rich black finish and enhances property appearance</p>
            </div>
          </div>
          
          <div className="cta-container">
            <p>Don't wait! Regular sealcoating every 2-3 years is recommended for Indianapolis driveways and parking lots.</p>
            <button className="cta-button" onClick={() => scrollToSection('Quote')}>
              Schedule Sealcoating Service
            </button>
          </div>
        </div>
      </section>
      
      {/* FEATURED PROJECTS */}
      <section className="section featured-projects">
        <div className="container">
          <div className="section-header">
            <h2>Our Recent Asphalt Projects in Indianapolis</h2>
            <div className="section-divider"></div>
          </div>
          <div className="projects-grid">
            <div className="project-item">
              <div className="project-image">
                <img src={parkingLotImg || 'https://via.placeholder.com/400x200/333333/FFFFFF?text=Residential+Sealcoating'} alt="Asphalt Sealcoating Indianapolis" />
              </div>
              <div className="project-info">
                <h3>Commercial Asphalt Sealcoating</h3>
                <p>Complete sealcoating and crack filling for residential homes and small commercial parking lots in Indianapolis by trusted asphalt contractors.</p>
              </div>
            </div>
            <div className="project-item">
              <div className="project-image">
                <img src={drivewayImg || 'https://via.placeholder.com/400x200/444444/FFFFFF?text=Residential+Driveway'} alt="Driveway Paving Indianapolis" />
              </div>
              <div className="project-info">
                <h3>Residential Driveway Paving</h3>
                <p>Professional driveway paving and sealing services for a homeowner in Carmel by Phillips Paving Indianapolis.</p>
              </div>
            </div>
            <div className="project-item">
              <div className="project-image">
                <img src={asphaltParkinglot || 'https://via.placeholder.com/400x200/555555/FFFFFF?text=Crack+Filling'} alt="Asphalt Repair Indianapolis" />
              </div>
              <div className="project-info">
                <h3>Asphalt Repair Near Me</h3>
                <p>Extensive crack repair and patching for a business parking lot in Cumberland by expert asphalt contractors near me.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SERVICES SECTION */}
      <section id="services" className="section">
        <div className="container">
          <div className="section-header">
            <h2>Asphalt Services in Indianapolis</h2>
            <div className="section-divider"></div>
          </div>
          <div className="service-grid">
            <div className="service-item featured-service">
              <div className="featured-badge">POPULAR</div>
              <div className="service-image">
                <img src={sealcoatingImg || 'https://via.placeholder.com/400x200/ff6600/FFFFFF?text=Sealcoating'} alt="Driveway Sealing Indianapolis" />
              </div>
              <h3>Asphalt Sealcoating</h3>
              <p>Extend pavement life, enhance appearance, and protect your Indianapolis asphalt from weather damage with our professional sealcoating services.</p>
              <a href="#Quote" className="service-button">Get a Quote</a>
            </div>
            <div className="service-item">
              <div className="service-image">
                <img src={crackFillingImg || 'https://via.placeholder.com/400x200/333333/FFFFFF?text=Crack+Filling'} alt="Asphalt Repair Near Me" />
              </div>
              <h3>Asphalt Crack Filling</h3>
              <p>Prevent potholes by stopping moisture infiltration with our expert asphalt repair services. Prolong the life of your pavement in Indianapolis.</p>
              <a href="#Quote" className="service-button">Get a Quote</a>
            </div>
            <div className="service-item">
              <div className="service-image">
                <img src={repairImg || 'https://via.placeholder.com/400x200/222222/FFFFFF?text=Patching'} alt="Asphalt Paving Indianapolis" />
              </div>
              <h3>Asphalt Paving & Patching</h3>
              <p>Professional driveway paving and asphalt repair services to restore structural integrity for driveways and parking lots in Indianapolis.</p>
              <a href="#Quote" className="service-button">Get a Quote</a>
            </div>
          </div>
        </div>
      </section>

      {/* ABOUT SECTION */}
      <section id="about" className="section alt-bg">
        <div className="container">
          <div className="section-header">
            <h2>About Phillips Paving Indianapolis</h2>
            <div className="section-divider"></div>
          </div>
          <div className="about-content">
            <div className="about-image">
              <img src={crewImg || 'https://via.placeholder.com/600x400/333333/FFFFFF?text=Phillips+Maintenance+Crew'} alt="Asphalt Contractors Indianapolis" />
              <div className="experience-badge">
                <span className="years">40+</span>
                <span className="text">YEARS</span>
                <span className="text">EXPERIENCE</span>
              </div>
            </div>
            <div className="about-text">
              <p>
                <strong>Established in 1983</strong>, Phillips Paving Inc is a family-owned asphalt company in Indianapolis 
                with over 40 years of experience delivering quality asphalt paving and maintenance solutions throughout the 
                Indianapolis area. We are fully licensed and insured, so you can 
                rest easy knowing your project is in safe hands.
              </p>
              <p>
                At Phillips Paving Indianapolis, we pride ourselves on our attention to detail, 
                professional crew, and commitment to customer satisfaction. Our team of asphalt contractors 
                is dedicated to providing exceptional service from the initial 
                consultation to the final inspection.
              </p>
              <p>
                We specialize in asphalt paving, driveway sealing, asphalt repair near me services, and crack filling. 
                Our preventative maintenance approach helps you avoid costly repairs and 
                extends the life of your existing asphalt in Indianapolis.
              </p>
              <div className="about-cta">
                <button onClick={() => scrollToSection('Quote')}>Get Started Today</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* REVIEWS SECTION */}
      <section id="reviews" className="section">
        <div className="container">
          <div className="section-header">
            <h2>What Our Indianapolis Asphalt Customers Say</h2>
            <div className="section-divider"></div>
          </div>
          <div className="reviews-content">
            <div className="review-box">
              <div className="stars">★★★★★</div>
              <blockquote>
                "Wonderful experience with Phillips Paving Indianapolis! Called & someone came out that day for a price quote. The driveway sealing job was scheduled for the following week & workers showed up on time. Workers were very nice & did a great job."
                <cite> – Barbara S. from Indianapolis</cite>
              </blockquote>
            </div>
            <div className="review-box">
              <div className="stars">★★★★★</div>
              <blockquote>
                "They did a great job with the asphalt repair and in a very timely manner. I was very pleased with the quality of work and the price. I would definitely recommend them to anyone looking for asphalt contractors near me."
                <cite> – William B. from Indianapolis</cite>
              </blockquote>
            </div>
            <div className="review-box">
              <div className="stars">★★★★★</div>
              <blockquote>
                "Very pleased with their asphalt paving service and results. Great crew, on time, friendly and good communicators. Sealed the driveway and filled all the cracks at a reasonable price compared to other asphalt companies in Indianapolis."
                <cite> – Rick M. from Indianapolis</cite>
              </blockquote>
            </div>
          </div>
          <div className="review-cta">
            <p>See what our customers have to say about our asphalt paving and sealcoating services in Indianapolis!</p>
            <a 
              href="https://www.angi.com/companylist/us/in/indianapolis/phillips-paving-inc-reviews-60198.htm" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="button"
            >
              Read More Reviews on Angi
            </a>
          </div>
        </div>
      </section>

      <section id="careers" className="section alt-bg">
        <div className="container">
          <JobApplicationForm />
        </div>
      </section>

      {/* CONTACT SECTION */}
      <section id="contact" className="section alt-bg">
        <div className="container">
          <div className="section-header">
            <h2>Contact Your Local Asphalt Contractors</h2>
            <div className="section-divider"></div>
          </div>
          <div className="contact-container">
            <div className="contact-info">
              <p>Ready to get started on your asphalt paving, driveway sealing, or asphalt repair project in Indianapolis? Call us or request a free quote below:</p>
              <p>
                <strong>Phone:</strong> <a href="tel:3177831228">(317) 783-1228</a><br/>
                <strong>Address:</strong> 1615 W Epler Ave, Indianapolis, IN 46217
              </p>     
              {/* Google Map Embed */}
              <iframe
                title="Phillips Paving Indianapolis Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.5036376071673!2d-86.18847148462043!3d39.68824957945269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886b50d140b1a0d1%3A0xf849abecde029b5a!2s1615%20W%20Epler%20Ave%2C%20Indianapolis%2C%20IN%2046217!5e0!3m2!1sen!2sus!4v1679086934395!5m2!1sen!2sus"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
           
          </div>
        </div>
      </section>

      {/* FOOTER */}
      <footer className="footer">
        <div className="container">
          <div className="footer-content">
            <div className="footer-info">
              <p>&copy; {new Date().getFullYear()} Phillips Paving Inc. All rights reserved.</p>
              <p>Proudly serving Indianapolis and surrounding areas with quality asphalt paving since 1983</p>
              <p>1615 W Epler Ave, Indianapolis, IN 46217</p>
              <p><a href="tel:3177831228">(317) 783-1228</a></p>
            </div>
            <div className="footer-links">
              <a href="#Quote">Free Quote</a>
              <a href="#services">Asphalt Services</a>
              <a href="#about">About</a>
              <a href="#reviews">Reviews</a>
              <a href="#contact">Contact</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

function QuoteSection() {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <section id="quote" className="section quote-section">
      <div className="container">
        <div className="section-header">
          <h2>Request a <span>Free Asphalt Estimate</span></h2>
          <div className="section-divider"></div>
          <p className="section-subheader">
            Get started with a no-obligation quote for your asphalt paving, driveway sealing, or asphalt repair project in Indianapolis.
          </p>
        </div>

        <div className="quote-container">
          <iframe
            data-tally-src="https://tally.so/embed/nWqOzP?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="500"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Phillips Paving Indianapolis Quote Form"
            style={{ border: 'none' }}
          ></iframe>
        </div>
      </div>
    </section>
  );
}

// Add this to App.js or a new file like ThankYou.js
function ThankYouPage() {
  return (
    <section className="section">
      <div className="container">
        <h2>Thank You!</h2>
        <p>We've received your request for an asphalt paving or sealcoating quote. A team member will reach out soon!</p>
      </div>
    </section>
  );
}


function JobApplicationForm() {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="section-header">
      <h2>Join the Phillips Paving Indianapolis Team</h2>
      <div className="section-divider"></div>
      <p className="section-subheader">
        We're always looking for reliable, hardworking people to join our asphalt crew. Fill out the application below to be considered.
      </p>
      
      <div className="job-form-container">
        <iframe
          data-tally-src="https://tally.so/embed/w7EXLZ?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
          loading="lazy"
          width="100%"
          height="1000"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Job Application Form"
          style={{ border: "none" }}
        ></iframe>
      </div>
    </div>
  );
}

/* CONTACT FORM COMPONENT */
function ContactForm() {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: ''
  });
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // In production, you'd send this data to your backend or an email service
    console.log('Form data:', formData);
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <div className="form-success">
        <h3>Thank you for contacting Phillips Paving Indianapolis!</h3>
        <p>We will get back to you shortly to discuss your asphalt project.</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <h3>Request a Free Asphalt Quote</h3>
      
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="name">Full Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            required
            value={formData.name}
            onChange={handleChange}
            placeholder="Your name"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            required
            value={formData.phone}
            onChange={handleChange}
            placeholder="Your phone number"
          />
        </div>
      </div>
      
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          required
          value={formData.email}
          onChange={handleChange}
          placeholder="Your email address"
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="service">Service Needed:</label>
        <select
          id="service"
          name="service"
          value={formData.service}
          onChange={handleChange}
          required
        >
          <option value="">Select a service</option>
          <option value="driveway-paving">Driveway Paving</option>
          <option value="asphalt-paving">Asphalt Paving</option>
          <option value="sealcoating">Asphalt Sealcoating</option>
          <option value="driveway-sealing">Driveway Sealing</option>
          <option value="crack-filling">Crack Filling</option>
          <option value="asphalt-repair">Asphalt Repair</option>
          <option value="pothole-repair">Pothole Repair</option>
          <option value="other">Other</option>
        </select>
      </div>
      
      <div className="form-group">
        <label htmlFor="message">Project Details:</label>
        <textarea
          id="message"
          name="message"
          rows="4"
          placeholder="Tell us about your driveway paving, parking lot, or asphalt project in Indianapolis..."
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      
      <button type="submit" className="submit-button">Get Your Free Asphalt Quote</button>
      <p className="form-disclaimer">We respect your privacy. Your information will never be shared with third parties.</p>
    </form>
  );
}

export default App;